import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';


function App() {
  return (
    <Outlet />
  )
}

export default App;
